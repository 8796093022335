import React, { memo, useState, useEffect, useRef, useMemo } from 'react';
import PropTypes from 'prop-types';

import Icon from 'components/Icon';

import {
  Wrapper,
  SelectButton,
  SelectLabel,
  Options,
  Option,
  OptionLabel,
  OptionInput,
  Placeholder,
  IconsWrapper,
  ClearValuesWrapper,
  PlaceholderValueText,
  Input,
} from './styles';

function Select({
  options,
  defaultValue,
  name,
  label,
  placeholder,
  onChange,
  autocompletePlaceholder,
}) {
  const isLongList = Object.keys(options).length > 15;

  const [isOpen, setOpen] = useState(false);
  const [search, setSearch] = useState(null);

  const wrapperRef = useRef();
  const autoCompleteInputRef = useRef();

  const handleToggleBtnClick = e => {
    e.preventDefault();
    setOpen(!isOpen);
  };

  const clearSearch = () => {
    setSearch(null);
  };

  const handleChange = event => {
    onChange({ [name]: event.target.value });
    setOpen(false);
    clearSearch();
  };

  const handleClearValue = () => {
    onChange({ [name]: '' });
  };

  const handleDocumentClick = event => {
    const targetElement = event.target;

    if (
      !wrapperRef.current?.contains(targetElement) &&
      wrapperRef.current !== targetElement
    ) {
      setOpen(false);
    }
  };

  const renderPlaceholder = useMemo(() => {
    if (defaultValue) {
      return `${options[defaultValue]?.label}`;
    }

    return placeholder;
  }, [defaultValue]);

  const handleSearch = e => {
    e.preventDefault();
    setSearch(e.target.value);
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleDocumentClick);
    return () => {
      document.removeEventListener('mousedown', handleDocumentClick);
    };
  }, []);

  return (
    <Wrapper ref={wrapperRef}>
      <SelectLabel>{label}</SelectLabel>

      <SelectButton
        data-empty
        data-active={isOpen}
        type="button"
        onClick={handleToggleBtnClick}
        title={label}
      >
        <Placeholder>
          {isOpen && isLongList ? (
            <Input
              value={search || ''}
              ref={autoCompleteInputRef}
              placeholder={autocompletePlaceholder}
              onChange={handleSearch}
              autoFocus
            />
          ) : (
            <>
              <PlaceholderValueText>{renderPlaceholder}</PlaceholderValueText>

              <IconsWrapper>
                {!!defaultValue && (
                  <ClearValuesWrapper onClick={handleClearValue}>
                    <Icon name="clear" />
                  </ClearValuesWrapper>
                )}

                <Icon name="dropdownArrow" />
              </IconsWrapper>
            </>
          )}
        </Placeholder>
      </SelectButton>

      {isOpen && (
        <Options>
          {Object.keys(options).map((item, index) => {
            const option = options[item];

            let isMatch = search;

            if (isMatch) {
              isMatch = option.label
                .toLowerCase()
                .includes(search.toLowerCase().trim());
              if (!isMatch) {
                return null;
              }
            }
            return (
              <Option htmlFor={`${name}-option-${index}`} key={option.value}>
                <OptionInput
                  id={`${name}-option-${index}`}
                  type="radio"
                  name={name}
                  value={option.value}
                  checked={defaultValue === option.value}
                  onChange={handleChange}
                />

                <OptionLabel>{option.label}</OptionLabel>
              </Option>
            );
          })}
        </Options>
      )}
    </Wrapper>
  );
}

Select.propTypes = {
  options: PropTypes.shape({
    value: PropTypes.oneOfType([
      PropTypes.bool,
      PropTypes.string,
      PropTypes.number,
    ]),
    label: PropTypes.node,
    disabled: PropTypes.bool,
  }).isRequired,
  defaultValue: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.node,
  placeholder: PropTypes.string.isRequired,
  autocompletePlaceholder: PropTypes.string,
};

Select.defaultProps = {
  defaultValue: null,
  label: null,
  autocompletePlaceholder: null,
};

export default memo(Select);
