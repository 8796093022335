import { defineMessages } from 'react-intl';

import { PRODUCT_TYPES } from 'constants/filters';

export const scope = 'HomePage.header.filters.type';

const types = Object.keys(PRODUCT_TYPES);

const defineObj = types.reduce((res, type) => {
  return { ...res, [type]: { id: `${scope}.${type}` } };
}, {});

export default defineMessages(defineObj);
