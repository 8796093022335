import styled from 'styled-components';

import {
  colorWhite,
  colorGrey200,
  colorGreen400,
  colorBlack900,
  mediaQueryMobileLargeMin,
} from 'styleUtils';

export const FiltersButton = styled.strong`
  width: 100%;
  cursor: pointer;
  color: ${colorBlack900};
  border: 1px solid ${colorGrey200};
  border-radius: 5px;
  background-color: ${colorWhite};
  height: 48px;
  line-height: 48px;
  text-align: center;
  font-weight: normal;
  font-size: 16px;

  ${'-webkit-touch-callout'}: none;
  ${'-webkit-user-select'}: none;
  ${'-khtml-user-select'}: none;
  ${'-moz-user-select'}: none;
  ${'-ms-user-select'}: none;
  user-select: none;

  svg {
    width: 21px;
    height: 21px;
    margin: 0 2px 2px 0;
  }

  &[data-active='true'] {
    margin-bottom: 0;
    border-color: ${colorGreen400};
    color: ${colorGreen400};
  }

  @media ${mediaQueryMobileLargeMin} {
    width: 133px;
  }
`;

export const FiltersButtonWrapper = styled.div`
  display: flex;
  width: 100%;
  height: 48px;
  margin-top: 24px;

  @media ${mediaQueryMobileLargeMin} {
    margin: 0;
    width: auto;
  }
`;
