import styled from 'styled-components';

import {
  colorBlack900,
  fontFamilyRoboto,
  mediaQueryDesktopMin,
  mediaQueryMobileLargeMin,
} from 'styleUtils';

export const CheckboxesBlockWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 24px;

  @media ${mediaQueryMobileLargeMin} {
    width: ${({ isWithoutWidth }) => (isWithoutWidth ? 'auto' : '33.333%')};
  }

  @media ${mediaQueryDesktopMin} {
    width: auto;
    margin-bottom: 0;
  }
`;

export const CheckboxesBlockTitle = styled.span`
  display: block;
  margin-bottom: ${({ isCustomTitleMargin }) =>
    isCustomTitleMargin ? '16px' : '20px'};

  font-family: ${fontFamilyRoboto};
  font-weight: 500;
  font-size: 13px;
  line-height: 13px;
  color: ${colorBlack900};
`;
