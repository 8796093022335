import React, { useCallback } from 'react';
import { FormattedMessage } from 'react-intl';
import { useHistory } from 'react-router-dom';

import { useSelectorProps } from 'hooks';

import Icon from 'components/Icon';

import { ResetFiltersButtonWrapper } from './styles';

function ResetFiltersButton() {
  const history = useHistory();

  const selectorProps = useSelectorProps();

  const handleClick = useCallback(() => {
    history.push({
      pathname: selectorProps.location.pathname,
      search: '',
    });
  }, [selectorProps]);

  return (
    <ResetFiltersButtonWrapper onClick={handleClick}>
      <Icon name="resetFilters" />
      <FormattedMessage id="MoreFilters.resetFiltersButton" />
    </ResetFiltersButtonWrapper>
  );
}

export default ResetFiltersButton;
