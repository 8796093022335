import React, { memo, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';

import { newPathname } from 'utils';

import {
  CompanyInfo,
  CompanyLogoWrapper,
  CompanyLogo,
  CompanyData,
  CompanyDataTitleBlock,
  CompanyDataTitle,
  CompanyDataMore,
  LicenseSpan,
  RatingValue,
} from './styles';

function BankCardCompanyInfo({
  logoSquare1x,
  name,
  ratingStability,
  license,
  slug,
}) {
  const linkToBank = useMemo(
    () =>
      newPathname({
        params: {},
        newValues: { slug },
      }),
    [slug]
  );

  return (
    <CompanyInfo>
      <CompanyLogoWrapper>
        <CompanyLogo src={logoSquare1x} alt={name} title={name} />
      </CompanyLogoWrapper>

      <CompanyData>
        <CompanyDataTitleBlock>
          <CompanyDataTitle preparedLink={linkToBank}>{name}</CompanyDataTitle>
        </CompanyDataTitleBlock>

        <CompanyDataMore>
          {!!ratingStability && (
            <RatingValue
              title={ratingStability}
              totalCount={5}
              filledCount={ratingStability}
            />
          )}
          {!!license && (
            <LicenseSpan>
              <FormattedMessage
                id="card.license"
                values={{ license: license?.split('№')[1] }}
              />
            </LicenseSpan>
          )}
        </CompanyDataMore>
      </CompanyData>
    </CompanyInfo>
  );
}

BankCardCompanyInfo.propTypes = {
  logoSquare1x: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  ratingStability: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.instanceOf(null),
  ]),
  license: PropTypes.string.isRequired,
  slug: PropTypes.string.isRequired,
};

BankCardCompanyInfo.defaultProps = {
  ratingStability: null,
};

export default memo(BankCardCompanyInfo);
