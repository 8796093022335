import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';

import Modal from 'components/Modal';

import { AdvertiserNoteText, CloseBtn, ModalText, ModalTitle } from './styles';

const AdvertiserNote = () => {
  const [isOpenModal, setOpenModal] = useState(false);

  const modalHandler = () => setOpenModal(!isOpenModal);

  return (
    <>
      <AdvertiserNoteText onClick={modalHandler}>
        <FormattedMessage id="header.advertiser_note" />
      </AdvertiserNoteText>

      <Modal
        isOpen={isOpenModal}
        onRequestClose={modalHandler}
        contentProps={{
          className: 'AdvertiserNoteModalContent',
          maxWith: '400px',
        }}
      >
        <ModalTitle>
          <FormattedMessage id="header.advertiser_note" />
        </ModalTitle>

        <ModalText>
          <FormattedMessage id="header.modal_text" />
        </ModalText>

        <CloseBtn type="button" onClick={modalHandler}>
          <FormattedMessage id="hide" />
        </CloseBtn>
      </Modal>
    </>
  );
};

export default AdvertiserNote;
