import styled from 'styled-components';

import { mediaQueryMobileLargeMin } from 'styleUtils';

export const Wrapper = styled.div`
  height: 100%;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 10000;

  &[data-open='false'] {
    display: none;
  }

  .WizardModalContent {
    background-color: #fff;
    border-radius: 2px;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.14),
      inset 0px 1px 0px rgba(203, 218, 244, 0.5);
    max-height: calc(100vh - 32px);
    max-width: 400px;
    min-height: 400px;
    overflow: auto;
    display: flex;
    justify-content: center;
    padding: 0;
    width: 100%;

    @media (min-height: 720px) {
      max-height: 680px;
    }

    form.TR_WL_Page-xs {
      padding: 24px !important;
    }
  }
`;

export const Overlay = styled.div`
  background-color: rgba(0, 0, 0, 0.3);
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 1;
`;

export const Content = styled.div`
  background: #fff;
  box-shadow: 0 16px 32px rgba(0, 0, 0, 0.5);
  border-radius: 4px;
  padding: 16px;
  position: absolute;
  left: 16px;
  top: 50%;
  transform: translateX(-8px) translateY(-50%);
  z-index: 2;

  @media ${mediaQueryMobileLargeMin} {
    max-width: ${({ maxWith }) => maxWith || '100%'};
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
  }
`;
