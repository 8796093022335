import React, { useCallback, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';

import FilterCheckboxesBlocks from 'components/FilterCheckboxesBlocks';
import FilterCheckboxesBlock from 'components/FilterCheckboxesBlock';
import MultiSelect from 'components/SelectMultiple';
import Select from 'components/Select';
import ResetFiltersButton from 'components/ResetFiltersButton';

import { newSearch, newPathname } from 'utils';

import { useSelectorProps } from 'hooks';

import { getFormattedSearchParams, getCurrenciesOptions } from 'selectors';

import {
  ACTIVITY_TYPE,
  CLIENT_TYPES,
  ACTIVITY_TYPE_KEYS,
} from 'constants/filters';

import { MainFiltersWrapper, MoreFiltersWrapper } from './styles';

function MoreFilters() {
  const selectorProps = useSelectorProps();

  const {
    intl: { formatMessage },
  } = selectorProps;

  const history = useHistory();

  const activityTypeOptions = useMemo(
    () =>
      Object.keys(ACTIVITY_TYPE).reduce((res, activity) => {
        return {
          ...res,
          [activity]: {
            label: formatMessage({
              id: `MoreFilters.multiSelect.activityType.${activity}`,
            }),
            value: activity,
          },
        };
      }, {}),
    [ACTIVITY_TYPE]
  );

  const searchParams = useSelector(state =>
    getFormattedSearchParams(state, selectorProps)
  );

  const currenciesOption = useSelector(state =>
    getCurrenciesOptions(state, selectorProps)
  );

  const {
    params: { clientType },
  } = selectorProps;

  const activityTypesSet = new Set(searchParams.activityType || []);

  if (clientType === CLIENT_TYPES.it) {
    activityTypesSet.add(ACTIVITY_TYPE_KEYS.it);
  }

  const defaultValuesActivityType = Array.from(activityTypesSet);

  const handleValue = useCallback(
    newObj =>
      history.push({
        pathname:
          newObj?.activityType?.find(el => el === ACTIVITY_TYPE_KEYS.it) &&
          clientType === CLIENT_TYPES.it
            ? selectorProps.location.pathname
            : newPathname({
                params: selectorProps.params,
                newValues: { clientType: null },
              }),
        search: newSearch({
          params: searchParams,
          newValues:
            clientType === CLIENT_TYPES.it
              ? {
                  ...newObj,
                  activityType: newObj.activityType.filter(
                    el => el !== ACTIVITY_TYPE_KEYS.it
                  ),
                }
              : newObj,
        }),
      }),
    [selectorProps]
  );

  return (
    <MoreFiltersWrapper>
      <MainFiltersWrapper>
        <MultiSelect
          name="activityType"
          label={formatMessage({ id: 'MoreFilters.multiSelect.activityType' })}
          defaultValues={defaultValuesActivityType}
          placeholder={formatMessage({
            id: 'MoreFilters.multiSelect.activityType.placeholder',
          })}
          autocompletePlaceholder={formatMessage({
            id: 'MoreFilters.multiSelect.activityType.autocompletePlaceholder',
          })}
          options={activityTypeOptions}
          handleValue={handleValue}
        />

        <FilterCheckboxesBlock
          block="monthlyFee"
          blockItems={['free']}
          isWithoutWidth
          isCustomTitleMargin
        />

        <Select
          options={currenciesOption}
          defaultValue={searchParams?.currency?.[0] || ''}
          name="currency"
          label={formatMessage({ id: 'MoreFilters.select.currency' })}
          placeholder={formatMessage({
            id: 'MoreFilters.select.currency.placeholder',
          })}
          autocompletePlaceholder={formatMessage({
            id: 'MoreFilters.select.currency.autocompletePlaceholder',
          })}
          onChange={handleValue}
        />
      </MainFiltersWrapper>

      <FilterCheckboxesBlocks />

      <ResetFiltersButton />
    </MoreFiltersWrapper>
  );
}

export default MoreFilters;
