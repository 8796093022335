import React, { memo, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import PropTypes from 'prop-types';

import { useSelectorProps } from 'hooks';

import { getFormattedSearchParams } from 'selectors';

import FilterCheckboxItem from 'components/FilterCheckboxItem';

import messages from './messages';

import { CheckboxesBlockWrapper, CheckboxesBlockTitle } from './styles';

function FilterCheckboxesBlock({
  block,
  blockItems,
  isWithoutWidth,
  isCustomTitleMargin,
}) {
  const { formatMessage } = useIntl();

  const selectorProps = useSelectorProps();

  const searchParams = useSelector(state =>
    getFormattedSearchParams(state, selectorProps)
  );

  const check = useCallback(
    (blockCheck, item) => {
      return (
        Object.keys(searchParams).includes(blockCheck) &&
        searchParams[blockCheck].includes(item)
      );
    },
    [searchParams]
  );

  return (
    <CheckboxesBlockWrapper isWithoutWidth={isWithoutWidth}>
      <CheckboxesBlockTitle isCustomTitleMargin={isCustomTitleMargin}>
        {formatMessage(messages[block])}
      </CheckboxesBlockTitle>
      {blockItems.map(item => (
        <FilterCheckboxItem
          key={item}
          item={item}
          block={block}
          checked={check(block, item)}
          searchParams={searchParams}
        />
      ))}
    </CheckboxesBlockWrapper>
  );
}

FilterCheckboxesBlock.propTypes = {
  block: PropTypes.string.isRequired,
  blockItems: PropTypes.arrayOf(PropTypes.string).isRequired,
  isWithoutWidth: PropTypes.bool,
  isCustomTitleMargin: PropTypes.bool,
};

FilterCheckboxesBlock.defaultProps = {
  isWithoutWidth: false,
  isCustomTitleMargin: false,
};

export default memo(FilterCheckboxesBlock);
