import { defineMessages } from 'react-intl';

import { CheckboxFilters } from 'constants/filters';

export const scope = 'HomePage.header.filters.checkboxesBlock';

const blocks = [...Object.keys(CheckboxFilters), 'monthlyFee'];

const defineObj = blocks.reduce((res, block) => {
  return { ...res, [block]: { id: `${scope}.${block}` } };
}, {});

export default defineMessages(defineObj);
