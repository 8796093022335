import styled from 'styled-components';

import {
  colorWhite,
  colorBlack900,
  colorGrey200,
  fontFamilyRoboto,
  mediaQueryMobileLargeMin,
  mediaQueryDesktopMin,
} from 'styleUtils';

export const Wrapper = styled.div`
  display: flex;
  flex-flow: row wrap;
  width: 100%;
  margin: 0 0 24px 0;
  position: relative;
  flex-direction: column;

  @media ${mediaQueryMobileLargeMin} {
    width: 177px;
    margin: 0;
  }

  &[data-is-open='true'] {
    z-index: 999;
  }
`;

export const SelectButton = styled.button`
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 40px;
  background-color: ${colorWhite};
  color: ${colorBlack900};
  border: 1px solid ${colorGrey200};
  border-radius: 2px;
  padding: 0 12px;

  @media ${mediaQueryMobileLargeMin} {
    max-width: 177px;
  }

  &:focus {
    outline: none;
  }

  svg {
    width: 16px;
    height: 21px;
  }
`;

export const Placeholder = styled.span`
  font-size: 14px;
  line-height: 24px;
  color: ${colorBlack900};
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

export const SelectLabel = styled.span`
  margin-bottom: 8px;

  font-family: ${fontFamilyRoboto};
  font-weight: 500;
  font-size: 13px;
  line-height: 13px;
  color: ${colorBlack900};
`;

export const ClearValuesWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 14px;

  svg {
    height: 14px;
    width: 14px;
  }
`;

export const IconsWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-left: auto;
`;

export const PlaceholderValueText = styled.span`
  overflow: hidden;
  padding-right: 8px;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 100%;
`;

export const Options = styled.div`
  background-color: ${colorWhite};
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.16);
  border-radius: 2px;
  left: 0;
  max-height: 312px;
  overflow: auto;
  position: absolute;
  top: 61px;
  width: 100%;
  z-index: 9;
  display: block;

  @media ${mediaQueryMobileLargeMin} {
    max-width: 240px;
  }

  &::-webkit-scrollbar {
    width: 3px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.1);
    border-radius: 3px;
    max-height: 50px;
  }

  &::-webkit-scrollbar-track {
    background-color: ${colorWhite};
  }

  &:focus {
    outline: 0;
    outline: none;
  }
`;

export const Option = styled.label`
  display: block;
`;

export const OptionInput = styled.input`
  display: none;
`;

export const OptionLabel = styled.span`
  cursor: pointer;
  display: block;
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  margin: 0;
  overflow: hidden;
  padding: 16px;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;

  &:hover,
  &.focus-visible {
    background-color: #f4faf3;
    color: #2d2d2d;
  }

  input:checked + & {
    background-color: #f4faf3;
    position: relative;

    &::after {
      content: '';
      position: absolute;
      display: block;
      height: 9px;
      right: 16px;
      top: 50%;
      transform: rotate(45deg) translateY(-50%);
      transform-origin: right center;
      width: 5px;
      border-color: rgb(35, 156, 19);
      border-style: solid;
      border-width: 0px 2px 2px 0px;
    }
  }
`;

export const Input = styled.input`
  height: 24px;
  font-size: 16px;
  line-height: 25px;
  border: none !important;
  color: ${colorBlack900};
  width: 77%;

  @media ${mediaQueryMobileLargeMin} {
    width: 166px;
  }

  @media ${mediaQueryDesktopMin} {
    font-size: 14px;
    line-height: 24px;
  }

  &:focus {
    outline: none;
  }

  &::placeholder {
    font-size: 14px;
    line-height: 24px;
    color: #87928c;
  }
`;
