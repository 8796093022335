import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import AdvertiserNote from 'components/AdvertiserNote';

import { Wrapper, UpdateText } from './styles';

const UpdatedInfo = memo(({ updated, isCompanyPage }) => (
  <Wrapper isCompanyPage={isCompanyPage}>
    <UpdateText>
      <FormattedMessage id="header.updated" />
      &nbsp;
      {updated}
    </UpdateText>

    <AdvertiserNote />
  </Wrapper>
));

UpdatedInfo.propTypes = {
  updated: PropTypes.string,
  isCompanyPage: PropTypes.bool,
};

UpdatedInfo.defaultProps = {
  updated: '',
  isCompanyPage: false,
};

export default UpdatedInfo;
