import { defineMessages } from 'react-intl';

import { CheckboxFilters } from 'constants/filters';

export const scope = 'HomePage.header.filters.checkboxesItem';

const items = Object.values(CheckboxFilters).reduce((res, itemObj) => {
  return [...res, ...Object.keys(itemObj)];
}, []);

const defineObj = [...items, 'free'].reduce((res, item) => {
  return { ...res, [item]: { id: `${scope}.${item}` } };
}, {});

export default defineMessages(defineObj);
