import React, { memo, useEffect, useMemo, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

import Page from 'components/Page';
import HomePageHeader from 'components/HomePageHeader';
import PageInner from 'components/PageInner';
import PageContent from 'components/PageContent';
import PageSidebar from 'components/PageSidebar';
import LayoutContainer from 'components/LayoutContainer';
import PageFooter from 'components/PageFooter';
import ProductsList from 'components/ProductsList';

import { useSelectorProps } from 'hooks';
import { fetchProductsByFilters } from 'actions';

import {
  getCompaniesWithProducts,
  getProductsIsLoaded,
  getProductsIsFetching,
  getProductsIsRequestFailed,
  getOrganizationsIsRequestFailed,
  getFormattedSearchParams,
} from 'selectors';

import Content from './styles';

const HomePage = memo(({ loadData, staticContext }) => {
  const selectorProps = useSelectorProps();

  const dispatch = useDispatch();

  const didMount = useRef(null);

  const { type, ...otherParams } = useSelector(state =>
    getFormattedSearchParams(state, selectorProps)
  );

  const isLoaded = useSelector(state =>
    getProductsIsLoaded(state, selectorProps)
  );

  const isFetching = useSelector(state =>
    getProductsIsFetching(state, selectorProps)
  );

  const organizationsIsRequestFailed = useSelector(state =>
    getOrganizationsIsRequestFailed(state, selectorProps)
  );

  const productsIsRequesFailed = useSelector(state =>
    getProductsIsRequestFailed(state, selectorProps)
  );

  const visible = useSelector(state =>
    getCompaniesWithProducts(state, selectorProps)
  );

  const productsCount = useMemo(
    () =>
      visible.reduce((res, curr) => {
        return res + curr?.products?.length;
      }, 0),
    [visible]
  );

  useEffect(() => {
    loadData(dispatch, selectorProps);
  }, [selectorProps]);

  if (
    (organizationsIsRequestFailed || productsIsRequesFailed) &&
    staticContext
  ) {
    staticContext.status = 404;
  }

  useEffect(() => {
    if (didMount.current) {
      dispatch(fetchProductsByFilters(selectorProps));
    }

    didMount.current = true;
  }, [didMount.current, JSON.stringify(otherParams)]);

  return (
    <Page>
      <HomePageHeader />

      <Content>
        <LayoutContainer>
          <PageInner>
            <PageContent>
              <ProductsList
                visibleProducts={visible}
                productsCount={productsCount}
                isLoaded={isLoaded}
                isFetching={isFetching}
                isRequestFailed={
                  organizationsIsRequestFailed || productsIsRequesFailed
                }
              />

              <PageFooter isShowTextBlocks />
            </PageContent>

            <PageSidebar />
          </PageInner>
        </LayoutContainer>
      </Content>
    </Page>
  );
});

HomePage.propTypes = {
  loadData: PropTypes.func.isRequired,
  staticContext: PropTypes.objectOf(PropTypes.any),
};

HomePage.defaultProps = {
  staticContext: null,
};

export default withRouter(HomePage);
