import React, { memo } from 'react';
import PropTypes from 'prop-types';

import { Wrapper, List, StarIcon } from './styles';

const StarsList = ({ totalCount, filledCount, ...props }) => {
  return (
    <Wrapper {...props}>
      <List>
        {Array.from({ length: totalCount }).map((item, index) => (
          <StarIcon
            data-filled={false}
            name="star"
            key={index} //eslint-disable-line
            data-rating={index + 1}
          />
        ))}
      </List>

      <List
        style={{
          overflow: 'hidden',
          width: `${(filledCount / totalCount) * 100}%`,
        }}
      >
        {Array.from({ length: totalCount }).map((item, index) => (
          <StarIcon
            data-filled
            name="star"
            key={index} //eslint-disable-line
          />
        ))}
      </List>
    </Wrapper>
  );
};

StarsList.propTypes = {
  totalCount: PropTypes.number,
  filledCount: PropTypes.number,
};

StarsList.defaultProps = {
  totalCount: 1,
  filledCount: 1,
};

export default memo(StarsList);
