import styled from 'styled-components';

import { mediaQueryMobileLargeMin } from 'styleUtils';

export const AllFiltersWrapper = styled.div`
  width: 100%;
`;

export const MainFilterWrapper = styled.form`
  display: flex;
  flex-flow: row wrap;
  width: 100%;

  @media ${mediaQueryMobileLargeMin} {
    display: flex;
    margin: 0;
    padding: 0;
    width: 100%;
    align-items: center;
    justify-content: center;
    flex-flow: row nowrap;
  }
`;
