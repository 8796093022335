import styled from 'styled-components';

import { colorWhite } from 'styleUtils';

export const BankCardListWrapper = styled.div`
  width: 100%;
  margin: 0 0 8px 0;

  & :last-child {
    margin-bottom: 0;
  }
`;

export const OtherCardsWrapper = styled.div`
  display: ${({ isOpenOtherCards }) => (isOpenOtherCards ? 'block' : 'none')};
  width: 100%;
  margin: 4px 0 0 0;
`;

export const ListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: ${colorWhite};
  padding: 24px 16px 0 16px;

  &:not(:first-child) {
    margin: 4px 0 0 0;
  }
`;

export const CompanyItem = styled.div``;

export const MoreCardsButton = styled.div`
  width: 100%;
  margin: 1px 0 0 0;
  padding: 16px 0;
  background-color: ${colorWhite};
  text-align: center;
`;
