import React from 'react';

import { CheckboxFilters } from 'constants/filters';

import FilterCheckboxesBlock from 'components/FilterCheckboxesBlock';

import { CheckboxesBlocksWrapper } from './styles';

function FilterCheckboxesBlocks() {
  const checkboxesBlocks = Object.keys(CheckboxFilters);

  return (
    <CheckboxesBlocksWrapper>
      {checkboxesBlocks.map(block => {
        const blockItems = Object.keys(CheckboxFilters[block]);

        return (
          <FilterCheckboxesBlock
            key={block}
            blockItems={blockItems}
            block={block}
          />
        );
      })}
    </CheckboxesBlocksWrapper>
  );
}

export default FilterCheckboxesBlocks;
