import React, { memo } from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';

import Icon from 'components/Icon';

import messages from './messages';

import { FiltersButtonWrapper, FiltersButton } from './styles';

function FilterButton({ isOpen, onButtonCLick }) {
  return (
    <FiltersButtonWrapper>
      <FiltersButton data-active={isOpen} onClick={onButtonCLick}>
        <Icon name="filter" />

        <FormattedMessage {...messages.filterButton} />
      </FiltersButton>
    </FiltersButtonWrapper>
  );
}

FilterButton.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onButtonCLick: PropTypes.func.isRequired,
};

export default memo(FilterButton);
