import styled from 'styled-components';

import {
  colorBlue10,
  green50,
  colorBlack900,
  fontFamilyRoboto,
  fontFamilyRaleway,
} from 'styleUtils';

export const AdvertiserNoteText = styled.p`
  font-family: ${fontFamilyRoboto};
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 24px;
  color: ${colorBlue10};
  transition: 0.2s;

  &:hover {
    cursor: pointer;
    opacity: 0.8;
  }
`;

export const CloseBtn = styled.button`
  display: block;
  font-family: ${fontFamilyRaleway};
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  color: ${green50};
  margin: 24px 0 8px auto;
  border: none;
  background: transparent;
  outline: none;
  transition: 0.2s;

  &:hover {
    opacity: 0.8;
  }
`;

export const ModalTitle = styled.h2`
  font-family: ${fontFamilyRaleway};
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 24px;
  color: ${colorBlack900};
  margin: 0 0 8px 0;
`;

export const ModalText = styled.p`
  font-family: ${fontFamilyRoboto};
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  color: ${colorBlack900};
`;
