import React, { memo, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { stringify } from 'query-string';

import PropTypes from 'prop-types';

import Icon from 'components/Icon';

import messages from './messages';

import {
  CheckboxContainer,
  StyledCheckbox,
  HiddenCheckbox,
  CheckboxWrapper,
  CheckboxText,
} from './styles';

function FilterCheckboxItem({ item, block, checked, searchParams }) {
  const { formatMessage } = useIntl();

  const history = useHistory();

  const handleCheckboxChange = useCallback(
    e => {
      const newLocationSearchObj = e.target.checked
        ? {
            ...searchParams,
            [block]:
              searchParams[block]?.length > 0
                ? [...searchParams[block], item]
                : [item],
          }
        : {
            ...searchParams,
            [block]: searchParams[block].filter(el => el !== item),
          };

      history.push({
        search: stringify(newLocationSearchObj, {
          arrayFormat: 'comma',
        }),
      });
    },
    [searchParams]
  );

  return (
    <CheckboxWrapper>
      <CheckboxContainer>
        <HiddenCheckbox checked={checked} onChange={handleCheckboxChange} />

        <StyledCheckbox checked={checked}>
          <Icon name="checked" />
        </StyledCheckbox>
      </CheckboxContainer>
      <CheckboxText>{formatMessage(messages[item])}</CheckboxText>
    </CheckboxWrapper>
  );
}

FilterCheckboxItem.propTypes = {
  item: PropTypes.string.isRequired,
  block: PropTypes.string.isRequired,
  checked: PropTypes.bool.isRequired,
  searchParams: PropTypes.objectOf(PropTypes.array).isRequired,
};

export default memo(FilterCheckboxItem);
