import React, { useState, useEffect, useRef, memo, useMemo } from 'react';
import { useIntl, FormattedMessage } from 'react-intl';

import { arrayOf, string, shape, func } from 'prop-types';

import Icon from 'components/Icon';

import {
  Wrapper,
  SelectButton,
  SelectLabel,
  Options,
  Option,
  Checkbox,
  Placeholder,
  OptionLabel,
  OptionWrapper,
  InputWrapper,
  ClearWrapper,
  Input,
  ClearValuesWrapper,
  PlaceholderValueText,
  PlaceholderMoreText,
  IconsWrapper,
} from './styles';

const MultiSelect = memo(
  ({
    options,
    defaultValues,
    name,
    placeholder,
    handleValue,
    autocompletePlaceholder,
    label,
  }) => {
    const [values, setValues] = useState(defaultValues);
    const [isOpen, setIsOpen] = useState(false);
    const [search, setSearch] = useState(null);

    const intl = useIntl();

    const wrapperRef = useRef();
    const autoCompleteInputRef = useRef();
    const didMountCurrent = useRef(false);

    const handleDocumentClick = event => {
      const targetElement = event.target;

      if (
        !wrapperRef.current?.contains(targetElement) &&
        wrapperRef.current !== targetElement
      ) {
        setIsOpen(false);
        setSearch(null);
      }
    };

    const handleDropdownClick = e => {
      e.preventDefault();
      setIsOpen(!isOpen);
    };

    const handleChange = (value, checked) => {
      if (checked) {
        setValues(prevState => prevState.filter(item => item !== value));
      } else {
        setValues(prevState => [...prevState, value]);
      }
    };

    const isSelectedAll = useMemo(() => {
      return values.length === Object.keys(options).length;
    }, [options, values]);

    const renderPlaceholder = useMemo(() => {
      if (isSelectedAll) {
        return intl.formatMessage({ id: 'select.all' });
      }

      if (values.length > 0) {
        return `${options[values[0]]?.label}`;
      }

      return placeholder;
    }, [values]);

    const handleSearch = e => {
      e.preventDefault();
      setSearch(e.target.value);
    };

    const clearSearch = () => {
      setSearch(null);
    };

    const clearSelectedValues = () => {
      setValues([]);
    };

    useEffect(() => {
      document.addEventListener('click', handleDocumentClick);
      return () => {
        document.removeEventListener('click', handleDocumentClick);
      };
    }, []);

    useEffect(() => {
      if (autoCompleteInputRef.current) {
        autoCompleteInputRef.current.focus();
      }
    }, [isOpen]);

    useEffect(() => {
      if (didMountCurrent.current) {
        handleValue({ [name]: values });
      } else {
        didMountCurrent.current = true;
      }
    }, [values]);

    useEffect(() => {
      if (values.length && !defaultValues.length) {
        clearSelectedValues();
      }
    }, [defaultValues]);

    return (
      <Wrapper ref={wrapperRef}>
        <SelectLabel>{label}</SelectLabel>

        <SelectButton onClick={handleDropdownClick}>
          <Placeholder>
            <PlaceholderValueText>{renderPlaceholder}</PlaceholderValueText>

            {values.length > 1 && !isSelectedAll && (
              <PlaceholderMoreText>
                <FormattedMessage id="MoreFilters.multiSelect.andMore" />

                {` + ${values.length - 1}`}
              </PlaceholderMoreText>
            )}

            <IconsWrapper>
              {!!values.length && (
                <ClearValuesWrapper onClick={clearSelectedValues}>
                  <Icon name="clear" />
                </ClearValuesWrapper>
              )}

              <Icon name="dropdownArrow" />
            </IconsWrapper>
          </Placeholder>
        </SelectButton>

        {isOpen && (
          <Options>
            {/* <> */}
            {/* <> */}
            <InputWrapper data-has-text={!!search}>
              <Icon name="search" />
              <Input
                value={search || ''}
                ref={autoCompleteInputRef}
                placeholder={autocompletePlaceholder}
                onChange={handleSearch}
              />
              <ClearWrapper onClick={clearSearch} data-has-search={!!search}>
                <Icon name="clear" />
              </ClearWrapper>
            </InputWrapper>
            {/* </> */}
            {Object.keys(options).map((element, index) => {
              const option = options[element];
              const isSelected = !!(
                Array.isArray(values) &&
                values.find(item => item === option.value)
              );

              let isMatch = search;

              if (isMatch) {
                isMatch = option.label
                  .toLowerCase()
                  .includes(search.toLowerCase().trim());
                if (!isMatch) {
                  return null;
                }
              }

              return (
                <Option htmlFor={`${name}-option-${index}`} key={option.value}>
                  <OptionWrapper
                    data-is-checked={isSelected}
                    onClick={() => handleChange(option.value, isSelected)}
                  >
                    <Checkbox>
                      <Icon name="checked" />
                    </Checkbox>
                    <OptionLabel title={option.label}>
                      {option.label}
                    </OptionLabel>
                  </OptionWrapper>
                </Option>
              );
            })}
            {/* </> */}
          </Options>
        )}
      </Wrapper>
    );
  }
);

MultiSelect.propTypes = {
  options: shape({ label: string, value: string }).isRequired,
  defaultValues: arrayOf(string),
  name: string.isRequired,
  placeholder: string.isRequired,
  autocompletePlaceholder: string,
  label: string.isRequired,
  handleValue: func.isRequired,
};

MultiSelect.defaultProps = {
  defaultValues: [],
  autocompletePlaceholder: null,
};

export default MultiSelect;
