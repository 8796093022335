import React, { memo, useState, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import VisibilitySensor from 'react-visibility-sensor';
import PropTypes from 'prop-types';

import BankCardList from 'components/BankCardList';
import NoData from 'components/NoData';
import BankCardListLoader from 'components/BankCardListLoader';

import { useSelectorProps } from 'hooks';
import { fetchProductsByFilters } from 'actions';

import { List, ListHeader, TextCountWrapper, TextCount } from './styles';

function ProductsList({
  visibleProducts,
  productsCount,
  isFetching,
  isLoaded,
  isRequestFailed,
}) {
  const selectorProps = useSelectorProps();

  const dispatch = useDispatch();

  const history = useHistory();

  const [itemsToShowCount, changeItemsToShowCount] = useState(20);

  const handleListVisibilityChange = useCallback(
    isVisible => {
      if (isVisible && visibleProducts.length > itemsToShowCount) {
        changeItemsToShowCount(itemsToShowCount + 10);
      }
    },
    [visibleProducts, itemsToShowCount]
  );

  const handleFetchProducts = useCallback(() => {
    dispatch(fetchProductsByFilters(selectorProps));
  }, [dispatch, selectorProps]);

  const handleResetFilters = useCallback(() => {
    history.push({ pathname: '/' });
  }, []);

  return (
    <>
      <VisibilitySensor
        partialVisibility="bottom"
        onChange={handleListVisibilityChange}
      >
        <List>
          <ListHeader>
            <TextCountWrapper>
              <TextCount>
                <FormattedMessage
                  id="cardsListHeader.count"
                  values={{ count: productsCount }}
                />
              </TextCount>
            </TextCountWrapper>
          </ListHeader>

          {isFetching &&
            Array.from(
              Array(4)
              // eslint-disable-next-line react/no-array-index-key
            ).map((_, index) => <BankCardListLoader key={index} />)}

          {isRequestFailed ||
            (!visibleProducts?.length && !isFetching && (
              <NoData
                isRequestField={isRequestFailed}
                fetchData={handleFetchProducts}
                resetFilters={handleResetFilters}
              />
            ))}

          {isLoaded &&
            visibleProducts.map((item, index) => {
              if (index < itemsToShowCount) {
                return <BankCardList key={item.id} company={item} />;
              }

              return null;
            })}
        </List>
      </VisibilitySensor>
    </>
  );
}

ProductsList.propTypes = {
  visibleProducts: PropTypes.arrayOf(PropTypes.any).isRequired,
  productsCount: PropTypes.number.isRequired,
  isFetching: PropTypes.bool,
  isRequestFailed: PropTypes.bool,
  isLoaded: PropTypes.bool,
};

ProductsList.defaultProps = {
  isFetching: false,
  isLoaded: false,
  isRequestFailed: false,
};

export default memo(ProductsList);
