import styled from 'styled-components';

import StarsList from 'components/StarsList';
import Link from 'components/Link';

import {
  colorBlack500,
  colorGrey300,
  fontFamilyRaleway,
  fontFamilyRoboto,
  mediaQueryMobileLargeMin,
} from 'styleUtils';

export const CompanyInfo = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  width: 100%;
  margin: 0 0 24px 0;
`;

export const CompanyLogoWrapper = styled.div`
  flex: none;
  width: 32px;
  height: 32px;
  text-align: center;
`;

export const CompanyLogo = styled.img`
  width: 100%;
  height: auto;
  max-height: 100%;
`;

export const CompanyData = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 0 0 16px;
`;

export const CompanyDataTitleBlock = styled.div``;

export const CompanyDataTitle = styled(Link)`
  cursor: pointer;
  color: ${colorBlack500};
  font-family: ${fontFamilyRaleway};
  font-weight: bold;
  font-size: 14px;
  line-height: 14px;

  &:hover,
  &:active,
  &:focus {
    color: ${colorBlack500};
  }
`;

export const CompanyDataMore = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 6px 0 0 0;
`;

export const LicenseSpan = styled.span`
  font-family: ${fontFamilyRoboto};
  font-size: 13px;
  line-height: 13px;
  color: ${colorGrey300};
`;

export const RatingValue = styled(StarsList)`
  margin: 0 8.25px 0 0;
  * {
    padding-right: 0;
  }
  @media ${mediaQueryMobileLargeMin} {
    margin-left: 0;
  }
`;
