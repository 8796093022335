import React, { memo, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';

import { PRODUCT_TYPES } from 'constants/filters';

import { useSelectorProps } from 'hooks';
import { getFormattedSearchParams } from 'selectors';

import { newSearch, newPathname } from 'utils';

import messages from './messages';

import { SelectWrapper, SelectOption } from './styles';

function RkoTypeSelect({ defaultValueId }) {
  const selectorProps = useSelectorProps();

  const searchParams = useSelector(state =>
    getFormattedSearchParams(state, selectorProps)
  );

  const history = useHistory();

  const handleClick = useCallback(
    item => () => {
      if (defaultValueId === item) {
        return;
      }

      history.push({
        pathname: newPathname({
          params: selectorProps.params,
          newValues: { page: '' },
        }),
        search: newSearch({
          params: searchParams,
          newValues: { type: item !== PRODUCT_TYPES.all ? item : '' },
        }),
      });
    },
    [selectorProps]
  );

  return (
    <SelectWrapper>
      {Object.values(PRODUCT_TYPES).map(item => (
        <SelectOption
          key={item}
          onClick={handleClick(item)}
          $isActive={item === defaultValueId}
        >
          <FormattedMessage {...messages[item]} />
        </SelectOption>
      ))}
    </SelectWrapper>
  );
}

RkoTypeSelect.propTypes = {
  defaultValueId: PropTypes.string.isRequired,
};

export default memo(RkoTypeSelect);
