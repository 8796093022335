import React, { useMemo, useState, useCallback } from 'react';
import { useSelector } from 'react-redux';

import RkoTypeSelect from 'components/RkoTypeSelect';
import MoreFilters from 'components/MoreFilters';
import FilterButton from 'components/FilterButton';

import { useSelectorProps } from 'hooks';

import {
  getFormattedSearchParams,
  getFormattedRouterUrlState,
} from 'selectors';

import { PRODUCT_TYPES } from 'constants/index';

import { AllFiltersWrapper, MainFilterWrapper } from './styles';

function ProductsFiltersForm() {
  const [isOpen, setIsOpen] = useState(false);

  const handleFilterButtonClick = useCallback(() => {
    setIsOpen(!isOpen);
  }, [isOpen]);

  const selectorProps = useSelectorProps();

  const searchParams = useSelector(state =>
    getFormattedSearchParams(state, selectorProps)
  );

  const routerUrlState = useSelector(state =>
    getFormattedRouterUrlState(state, selectorProps)
  );

  const rkoType = useMemo(() => {
    return routerUrlState.page
      ? routerUrlState.page
      : searchParams?.type?.[0]?.toLowerCase() || PRODUCT_TYPES.all;
  }, [searchParams.type, routerUrlState.page]);

  return (
    <AllFiltersWrapper>
      <MainFilterWrapper noValidate>
        <RkoTypeSelect defaultValueId={rkoType} />

        <FilterButton isOpen={isOpen} onButtonCLick={handleFilterButtonClick} />
      </MainFilterWrapper>

      {isOpen && <MoreFilters />}
    </AllFiltersWrapper>
  );
}

export default ProductsFiltersForm;
