import styled from 'styled-components';

import { colorYellow } from 'styleUtils';

import Icon from 'components/Icon';

export const Wrapper = styled.span`
  align-items: flex-start;
  display: inline-flex;
  position: relative;
`;

export const List = styled.span`
  align-items: center;
  display: inline-flex;

  &:first-child {
    left: 0;
    overflow: hidden;
    position: absolute;
    top: 0;
  }
`;

export const StarIcon = styled(Icon)`
  height: 13px;
  fill: none;
  width: 12.75px;
  min-width: 12.75px;
  stroke: ${colorYellow};

  &[data-filled='true'] {
    fill: ${colorYellow};
  }

  &:not(:last-child) {
    margin-right: 0.75px;
  }
`;
