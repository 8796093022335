import styled from 'styled-components';

import {
  colorGrey200,
  colorGreen400,
  fontFamilyRoboto,
  colorBlack900,
} from 'styleUtils';

export const CheckboxWrapper = styled.label`
  display: flex;
  align-items: center;

  &:not(:last-child) {
    margin-bottom: 16px;
  }
`;

export const CheckboxContainer = styled.div`
  display: inline-block;
  vertical-align: middle;
`;

export const HiddenCheckbox = styled.input.attrs({ type: 'checkbox' })`
  border: 0;
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
`;

export const StyledCheckbox = styled.div`
  display: inline-block;
  margin-right: 16px;
  width: 24px;
  height: 24px;
  background: ${({ checked }) => checked && colorGreen400};
  border: ${({ checked }) => (checked ? `none` : `2px solid ${colorGrey200}`)};
  border-radius: 2px;
  cursor: pointer;
  transition: all 150ms;
  position: relative;
  left: 0px;
  top: 2px;

  & svg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 14px;
    height: 11px;
  }

  & * {
    visibility: ${({ checked }) => (checked ? 'visible' : 'hidden')};
  }
`;

export const CheckboxText = styled.span`
  ${'-webkit-touch-callout'}: none;
  ${'-webkit-user-select'}: none;
  ${'-khtml-user-select'}: none;
  ${'-moz-user-select'}: none;
  ${'-ms-user-select'}: none;
  user-select: none;

  font-family: ${fontFamilyRoboto};
  font-size: 14px;
  line-height: 24px;
  color: ${colorBlack900};
`;
