import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';

import {
  META_FOR_RKO_PAGE,
  META_FOR_FOP_PAGE,
  META_FOR_IT_PAGE,
  PRODUCT_TYPES,
  CLIENT_TYPES,
} from 'constants/index';
import {
  useSeoMeta,
  useWebPageSchema,
  useMetaPage,
  useSelectorProps,
} from 'hooks';
import { getCompaniesWithProducts, getUpdatedDate } from 'selectors';

import PageHelmet from 'components/PageHelmet';
import PageInner from 'components/PageInner';
import LayoutContainer from 'components/LayoutContainer';
import ProductsFiltersForm from 'components/ProductsFiltersForm';
import UpdatedInfo from 'components/UpdatedInfo';

import {
  Wrapper,
  Title,
  Description,
  HeaderText,
  WrapperContainer,
} from './styles';

const HomePageHeader = ({ match: { params } }) => {
  const selectorProps = useSelectorProps();

  const organizationsWithProducts = useSelector(state =>
    getCompaniesWithProducts(state, selectorProps)
  );

  const { clientType, page } = params;

  const updated = useSelector(state => getUpdatedDate(state, selectorProps));

  const { locale } = useIntl();

  const currentLocale = locale === 'ru' ? '' : '/ua';

  const isFopPage = page === PRODUCT_TYPES.fop;
  const isItPage = clientType === CLIENT_TYPES.it;

  const seoParams =
    (isItPage && META_FOR_IT_PAGE) ||
    (isFopPage && META_FOR_FOP_PAGE) ||
    META_FOR_RKO_PAGE;

  const { pageTitle, pageDescription, metaTitle, metaDescription } = useSeoMeta(
    seoParams
  );

  const meta = useMetaPage();

  const pageSchema = useWebPageSchema(
    {
      '@type': 'ItemList',
      name: pageTitle,
      itemListElement: organizationsWithProducts.slice(0, 5).map(el => {
        return {
          '@context': 'https://schema.org/',
          '@type': 'BankAccount',
          name: el.products[0].productName,
          provider: {
            '@type': 'BankOrCreditUnion',
            name: el.slug,
          },
          feesAndCommissionsSpecification: `${process.env.APP_MINFIN_DOMAIN}${currentLocale}${process.env.APP_ROUTER_BASENAME}/${el.slug}/`,
        };
      }),
    },
    metaTitle,
    metaDescription
  );

  const pageScripts = useMemo(
    () => [
      {
        type: 'application/ld+json',
        innerHTML: pageSchema,
      },
    ],
    [pageSchema]
  );

  return (
    <Wrapper>
      <LayoutContainer>
        <PageHelmet
          pageTitle={pageTitle}
          metaTitle={metaTitle}
          metaDescription={metaDescription}
          meta={meta}
          script={pageScripts}
        />

        <PageInner>
          <WrapperContainer>
            <UpdatedInfo updated={updated} />

            <HeaderText>
              <Title>{pageTitle}</Title>

              <Description>{pageDescription}</Description>
            </HeaderText>

            <ProductsFiltersForm />
          </WrapperContainer>
        </PageInner>
      </LayoutContainer>
    </Wrapper>
  );
};

HomePageHeader.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
    search: PropTypes.string.isRequired,
  }).isRequired,
  match: PropTypes.shape({
    params: PropTypes.objectOf(PropTypes.string).isRequired,
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
};

export default withRouter(HomePageHeader);
