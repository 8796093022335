import styled from 'styled-components';

import { mediaQueryMobileLargeMin, mediaQueryDesktopMin } from 'styleUtils';

export const CheckboxesBlocksWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: start;
  width: 100%;

  @media ${mediaQueryMobileLargeMin} {
    flex-direction: row;
    flex-wrap: wrap;
  }

  @media ${mediaQueryDesktopMin} {
    justify-content: space-between;
  }
`;
