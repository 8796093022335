import styled from 'styled-components';

import {
  colorBlack200,
  fontFamilyRoboto,
  mediaQueryMobileLargeMin,
} from 'styleUtils';

export const ResetFiltersButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  margin: 0 auto;

  font-family: ${fontFamilyRoboto};
  font-size: 14px;
  line-height: 24px;
  color: ${colorBlack200};

  & svg {
    width: 21px;
    height: 18px;

    margin: 0 7px 0 0;

    transition: all 0.2s linear 0s;
  }

  &:hover {
    & svg {
      transform: rotate(-360deg);
    }
  }

  @media ${mediaQueryMobileLargeMin} {
    position: absolute;
    top: 52px;
    right: 20px;
  }
`;
