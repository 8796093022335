import styled from 'styled-components';

import {
  colorGrey400,
  fontFamilyRoboto,
  mediaQueryMobileLargeMin,
} from 'styleUtils';

export const Wrapper = styled.div`
  display: flex;
  margin: 0 auto;
  width: 100%;
  padding: ${({ isCompanyPage }) => (isCompanyPage ? '0' : '0 16px')};
  margin-bottom: 12px;

  @media ${mediaQueryMobileLargeMin} {
    width: max-content;
    padding: ${({ isCompanyPage }) => (isCompanyPage ? '0 16px' : '0')};
    align-items: center;
    margin-bottom: ${({ isCompanyPage }) => (isCompanyPage ? '0' : '12px')};
  }
`;

export const UpdateText = styled.p`
  font-family: ${fontFamilyRoboto};
  font-style: normal;
  font-weight: 500;
  font-size: 11px;
  line-height: 24px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: ${colorGrey400};
  margin-right: 16px;
`;
