import styled from 'styled-components';

import {
  colorWhite,
  colorGrey200,
  colorBlack900,
  colorGreyDark,
  colorGrey40,
  colorGrey400,
  colorGreen50,
  colorGreen400,
  fontFamilyRoboto,
  mediaQueryMobileLargeMin,
  mediaQueryDesktopMin,
} from 'styleUtils';

export const Wrapper = styled.div`
  display: flex;
  flex-flow: row wrap;
  width: 100%;
  position: relative;
  flex-direction: column;
  margin-bottom: 24px;

  @media ${mediaQueryMobileLargeMin} {
    width: 386px;
  }

  @media ${mediaQueryDesktopMin} {
    margin-right: unset;
  }

  &[data-is-open='true'] {
    z-index: 999;
  }
`;

export const PlaceholderValueText = styled.span`
  overflow: hidden;
  padding-right: 8px;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 100%;
`;

export const PlaceholderMoreText = styled.span`
  white-space: nowrap;
`;

export const SelectLabel = styled.span`
  margin-bottom: 8px;

  font-family: ${fontFamilyRoboto};
  font-weight: 500;
  font-size: 13px;
  line-height: 13px;
  color: ${colorBlack900};
`;

export const SelectButton = styled.button`
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 40px;
  background-color: ${colorWhite};
  color: ${colorBlack900};
  border: 1px solid ${colorGrey200};
  border-radius: 2px;
  padding: 0 12px;

  @media ${mediaQueryMobileLargeMin} {
    max-width: 386px;
  }

  &:focus {
    outline: none;
  }

  svg {
    width: 16px;
    height: 21px;
  }
`;

export const Placeholder = styled.span`
  font-size: 14px;
  line-height: 24px;
  color: ${colorBlack900};

  display: flex;
  justify-content: space-between;
  width: 100%;
`;

export const Options = styled.div`
  background-color: ${colorWhite};
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.16);
  border-radius: 2px;
  left: 0;
  max-height: 312px;
  overflow: auto;
  position: absolute;
  top: 100%;
  width: 100%;
  z-index: 9;
  display: block;

  @media ${mediaQueryMobileLargeMin} {
    max-width: 386px;
  }

  &::-webkit-scrollbar {
    width: 3px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.1);
    border-radius: 3px;
    max-height: 50px;
  }

  &::-webkit-scrollbar-track {
    background-color: ${colorWhite};
  }

  &:focus {
    outline: 0;
    outline: none;
  }
`;

export const Option = styled.label`
  display: block;
`;

export const ClearValuesWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 14px;

  svg {
    height: 14px;
    width: 14px;
  }
`;

export const IconsWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-left: auto;
`;

export const InputWrapper = styled.div`
  display: inline-flex;
  align-items: center;
  width: 100%;
  height: 40px;
  padding: 12px 0 12px 16px;
  box-shadow: inset 0px -1px 0px ${colorGrey40};

  svg {
    margin-right: 6px;
    height: 16px;
    width: 16px;
  }
`;

export const ClearWrapper = styled.div`
  margin-left: 6px;
  cursor: pointer;
  display: none;

  svg {
    margin: 0;
    height: 14px;
    width: 14px;
  }

  &[data-has-search='true'] {
    display: block;
  }
`;

export const OptionWrapper = styled.div`
  display: flex;
  align-items: center;
  padding-left: 16px;

  &:hover,
  &.focus-visible {
    background-color: #f4faf3;
    color: ${colorGreyDark};
  }

  svg {
    display: none;
  }

  &[data-is-checked='true'] {
    background: ${colorGreen50};

    div:first-of-type {
      background: ${colorGreen400};
      border: none;
    }

    svg {
      width: 13px;
      height: 13px;
      padding: 3px 0 0 2px;
      display: block;
    }
  }
`;

export const Checkbox = styled.div`
  width: 16px;
  height: 16px;
  border: 2px solid ${colorGrey200};
  border-radius: 2px;
`;

export const OptionLabel = styled.span`
  cursor: pointer;
  display: block;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  margin: 0;
  overflow: hidden;
  padding: 8px 16px 8px 8px;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
`;

export const Input = styled.input`
  height: 24px;
  font-size: 16px;
  line-height: 25px;
  border: none !important;
  color: ${colorBlack900};
  width: 77%;

  /* @media ${mediaQueryMobileLargeMin} {
    width: 166px;
  } */

  @media ${mediaQueryDesktopMin} {
    font-size: 14px;
    line-height: 24px;
  }

  &:focus {
    outline: none;
  }

  &::placeholder {
    font-size: 14px;
    line-height: 24px;
    color: ${colorGrey400};
  }
`;
