import styled from 'styled-components';

import {
  colorGrey200,
  mediaQueryMobileLargeMin,
  mediaQueryTabletMin,
  mediaQueryDesktopMin,
} from 'styleUtils';

export const MoreFiltersWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  width: 100%;
  margin: 24px 0 0 0;
  position: relative;

  @media ${mediaQueryMobileLargeMin} {
    padding-top: 24px;
    border-top: 1px solid ${colorGrey200};
    &::before {
      height: 10px;
      width: 10px;
      position: absolute;
      content: '';
      background: white;
      border: 1px solid ${colorGrey200};
      border-bottom-width: 0;
      border-right-width: 0;
      transform: rotate(45deg);
      top: -5px;
      left: 69%;
    }
  }

  @media ${mediaQueryTabletMin} {
    &::before {
      left: 63.5%;
    }
  }

  @media ${mediaQueryDesktopMin} {
    &::before {
      left: 60.5%;
    }
  }
`;

export const MainFiltersWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  @media ${mediaQueryMobileLargeMin} {
    max-width: 386px;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  @media ${mediaQueryDesktopMin} {
    max-width: 80%;
  }
`;
