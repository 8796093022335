import React, { memo, useLayoutEffect } from 'react';
import PropTypes from 'prop-types';

import { Wrapper, Overlay, Content } from './styles';

const Modal = memo(
  ({
    isOpen,
    onRequestClose,
    children,
    overlayProps,
    contentProps,
    ...props
  }) => {
    useLayoutEffect(() => {
      // TODO: solve issue with body width and padding
      if (isOpen) {
        document.body.style.overflow = 'hidden';
      } else {
        document.body.style.overflow = null;
      }
    }, [isOpen]);

    return (
      <Wrapper {...props} data-open={isOpen}>
        <Overlay onClick={onRequestClose} {...overlayProps} />
        <Content {...contentProps}>{children}</Content>
      </Wrapper>
    );
  }
);

Modal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onRequestClose: PropTypes.func.isRequired,
  overlayProps: PropTypes.objectOf(PropTypes.any),
  contentProps: PropTypes.objectOf(PropTypes.any),
  children: PropTypes.node,
};

Modal.defaultProps = {
  children: null,
  overlayProps: {},
  contentProps: {},
};

export default Modal;
