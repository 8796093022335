import React, { memo, useState, useCallback, useMemo, useRef } from 'react';
import { useIntl } from 'react-intl';
import PropTypes from 'prop-types';

import BankCardCompanyInfo from 'components/BankCardCompanyInfo';
import DropdownButton from 'components/DropdownButton';
import BankCardItem from 'components/BankCardItem';

import { SCROLL_INTO_VIEW } from 'constants/index';

import {
  BankCardListWrapper,
  OtherCardsWrapper,
  ListWrapper,
  CompanyItem,
  MoreCardsButton,
} from './styles';

function BankCardList({ company }) {
  const { formatMessage } = useIntl();
  const ref = useRef();
  const [card, ...otherCards] = company.products;

  const [isOpenOtherCards, setIsOpenOtherCards] = useState(false);

  const handleShowOtherCards = useCallback(() => {
    setIsOpenOtherCards(!isOpenOtherCards);

    if (isOpenOtherCards) {
      ref.current.scrollIntoView({
        block: SCROLL_INTO_VIEW.start,
        behavior: SCROLL_INTO_VIEW.smooth,
      });
    }
  }, [isOpenOtherCards]);

  const showOtherCards = useMemo(
    () =>
      !!otherCards.length && (
        <OtherCardsWrapper isOpenOtherCards={isOpenOtherCards}>
          {otherCards.map(_card => (
            <ListWrapper key={_card.id}>
              <BankCardItem card={_card} />
            </ListWrapper>
          ))}
        </OtherCardsWrapper>
      ),
    [otherCards]
  );

  return (
    <BankCardListWrapper>
      <CompanyItem ref={ref}>
        <ListWrapper>
          <BankCardCompanyInfo {...company} />

          <BankCardItem card={card} />
        </ListWrapper>

        {showOtherCards}
      </CompanyItem>

      {!!otherCards.length && (
        <MoreCardsButton>
          <DropdownButton
            showMoreInfo={isOpenOtherCards}
            handleShowInfo={handleShowOtherCards}
          >
            {formatMessage(
              { id: 'BankCardList.moreCardsButton' },
              { count: otherCards.length }
            )}
          </DropdownButton>
        </MoreCardsButton>
      )}
    </BankCardListWrapper>
  );
}

BankCardList.propTypes = {
  company: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default memo(BankCardList);
