import styled from 'styled-components';

import {
  colorBlack900,
  colorBlack200,
  fontFamilyRaleway,
  fontFamilyRoboto,
  mediaQueryMobileMin,
  mediaQueryMobileLargeMin,
  mediaQueryDesktopMin,
} from 'styleUtils';

export const Wrapper = styled.header`
  background-color: #fff;
  width: 100%;
`;

export const WrapperContainer = styled.div`
  width: 100%;
  padding: 0 16px;

  @media ${mediaQueryMobileMin} {
    padding: 0;
  }
`;

export const HeaderText = styled.div`
  width: 100%;
  margin: 0 0 17px 0;

  @media ${mediaQueryMobileLargeMin} {
    margin: 0 0 24px 0;
  }
`;

export const Title = styled.h1`
  font-family: ${fontFamilyRaleway};
  font-size: 17px;
  line-height: 24px;
  font-weight: bold;
  margin: 0 auto 10px auto;
  text-align: center;
  color: ${colorBlack900};
  width: 100%;

  @media (min-width: 321px) {
    width: 320px;
  }

  @media ${mediaQueryMobileLargeMin} {
    width: 100%;
    font-size: 32px;
    line-height: 32px;
    margin-bottom: 24px;
  }

  @media ${mediaQueryDesktopMin} {
    margin-bottom: 16px;
  }
`;

export const Description = styled.p`
  font-family: ${fontFamilyRoboto};
  font-size: 14px;
  line-height: 24px !important;
  text-align: center;
  width: 100%;
  color: ${colorBlack200};
  padding: 0 8px;

  @media ${mediaQueryMobileLargeMin} {
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 24px;

    @media ${mediaQueryDesktopMin} {
      line-height: 16px;
    }
  }
`;
