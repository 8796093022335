import styled from 'styled-components';

import {
  colorWhite,
  colorBlack,
  colorGreen400,
  colorGrey200,
  colorGreen50,
  mediaQueryMobileLargeMin,
  mediaQueryMobileMin,
} from 'styleUtils';

export const SelectWrapper = styled.div`
  display: inline-flex;
  position: relative;
  height: 48px;
  border-radius: 5px 0 0 5px;
  width: 100%;

  ${'-webkit-touch-callout'}: none;
  ${'-webkit-user-select'}: none;
  ${'-khtml-user-select'}: none;
  ${'-moz-user-select'}: none;
  ${'-ms-user-select'}: none;
  user-select: none;

  div:first-of-type {
    border-radius: 5px 0 0 5px;
  }

  div:last-of-type {
    border-radius: 0 5px 5px 0;
  }

  div:not(:first-of-type) {
    width: 100%;
  }

  @media ${mediaQueryMobileMin} {
    padding: 0;
  }

  @media ${mediaQueryMobileLargeMin} {
    padding: 0 16px 0 0;
    margin-top: 0;
    width: auto;

    a:not(:first-of-type) {
      width: auto;
    }
  }
`;

export const SelectOption = styled.div`
  display: flex;
  cursor: pointer;
  background-color: ${({ $isActive }) =>
    $isActive ? colorGreen400 : colorWhite};
  color: ${({ $isActive }) => ($isActive ? colorWhite : colorBlack)};
  white-space: nowrap;
  min-width: 59px;
  width: fit-content;
  align-items: center;
  justify-content: center;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border: 1px solid ${colorGrey200};
  padding: 0 14px;
  text-align: center;

  &:not(:last-of-type) {
    margin-right: -1px;
  }

  ${({ $isActive }) => {
    if (!$isActive) {
      return `
        &:hover {
          background-color: ${colorGreen50};
        }
      `;
    }

    return `
      &:hover, &:active, &:focus {
        color: ${colorWhite};
      }
    `;
  }}
`;
